<template>
  <div class="fav">
    <center-title title="我的收藏" :border="false"></center-title>
    <navtop :navList="navList" @changeCurrent="changeCurrent">
      <div slot="right" class="nav-right row lighter nr">
        <el-checkbox
          @change="handleChekcAll"
          v-model="checkAll"
          size="medium"
          text-color="#666666"
          fill="#42aceb"
          >全选</el-checkbox
        >
        <div class="cancel row cursor" @click="changeStatus">
          <i class="iconfont lg mr5">&#xe610;</i>
          <span>取消收藏</span>
        </div>
        <!-- <button class="sure bg-center mr20 cursor">完成</button> -->
        <!-- <el-input placeholder="请输入内容">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input> -->
      </div>
    </navtop>
    <div class="fav-list row" v-if="goodsList.length">
      <div
        class="fav-item cursor"
        @click="handleSelectList(item.id)"
        v-for="(item, index) in goodsList"
        :key="index"
      >
        <div
          class="item-img row-center"
          :class="{ active: selectList.includes(item.id) }"
        >
          <el-image
            :src="item.image"
            style="width: 100%; height: 100%"
            fit="cover"
          ></el-image>
        </div>
        <div class="item-info" @click.stop="toProDetail(item.id)">
          <div class="info-title mb10 line1 xs lighter">
            {{ item.name }}
          </div>
          <div class="info-price">
            <make-priceformat
              :price="item.min_price"
              class="mr10"
              subscriptSize="16"
              firstSize="16"
              secondSize="16"
            ></make-priceformat>
            <!-- <make-priceformat
              price="89"
              class="mr10"
              subscriptSize="13"
              firstSize="13"
              secondSize="13"
              color="#A7A7A7"
              :undeline="true"
              :lineThrough="true"
            ></make-priceformat> -->
          </div>
        </div>
      </div>
      <div class="pagination row-center">
        <make-pagination
          @resetList="resetList"
          :orderObject="orderObject"
        ></make-pagination>
      </div>
    </div>
    <el-empty description="暂无收藏商品" v-else></el-empty>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import NavTop from "../components/NavTop";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    navtop: NavTop,
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      // navList: ["全部商品", "失效166", "降价"],
      navList: ["全部商品"],
      selectList: [],
      goodsList: [],
      currentList: [],
      checkAll: false,
      orderObject: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeCurrent(current) {},
    toProDetail(id) {
      this.$router.push({ path: "/prodetail", query: { id } });
    },
    handleSelectList(id) {
      let index = this.selectList.indexOf(id);
      if (index === -1) {
        this.selectList.push(id);
      } else {
        this.selectList.splice(index, 1);
      }
      this.checkAll = this.selectList.length === 20;
    },
    handleChekcAll(e) {
      if (this.checkAll) {
        this.selectList = this.currentList;
      } else {
        this.selectList = [];
      }
    },
    goodsCollectLists() {
      this.$api.goodsCollectListsApi({ page_size: 20 }).then((res) => {
        if (res.code == 1) {
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
          this.selectList = [];
          this.goodsList = lists;
          this.goodsList.forEach((element) => {
            this.currentList.push(element.id);
          });
        }
      });
    },
    changeStatus() {
      if (!this.selectList.length) {
        return this.$message.error("请先选择商品");
      }
      this.$confirm("此操作将取消选择的收藏, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.changeStatusApi({
            goods_array: this.selectList,
          });
          this.selectList = [];
          this.checkAll = false;
          this.goodsCollectLists();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getAfterSaleList();
    },
  },
  created() {
    this.goodsCollectLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.fav {
  width: 1170px;
  margin: 0 auto;
}
.nav-right {
  /deep/ .el-checkbox {
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
      &::after {
        transform: rotate(45deg) scale(1.5);
        top: 3px;
        left: 6px;
      }
    }
    .el-checkbox__input {
      line-height: inherit;
    }
    .el-checkbox__label {
      font-size: 16px;
      line-height: 30px;
    }
  }
  .cancel {
    flex-shrink: 0;
    margin: 0 35px;
    img {
      width: 20px;
    }
  }
  .sure {
    width: 80px;
    height: 30px;
    border: $-solid-border;
    flex-shrink: 0;
  }
  /deep/ .el-input {
    .el-input__inner {
      height: 30px;
      border-radius: 0;
      border: $-solid-border;
    }
    .el-input__suffix {
      right: 0;
      background-color: $-color-center;
      width: 40px;
      border: $-solid-border;
      border-left: none;
      color: $-color-lighter;
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}
.fav-list {
  flex-wrap: wrap;
  .fav-item {
    width: 188px;
    margin-right: 8px;
    margin-bottom: 20px;
    &:nth-child(6n) {
      margin-right: 0;
    }
    .item-img {
      width: 100%;
      height: 193px;
      border: $-solid-border;
      position: relative;
      margin-bottom: 15px;
      &::after {
        content: "";
        position: absolute;
        width: 44px;
        height: 41px;
        background-image: url("~@/assets/icon/icon-select2.png");
        background-size: 100% 100%;
        right: 0;
        top: 0;
      }
      &.active {
        // &:hover {
        border: 1px solid $-color-primary;
        &::after {
          background-image: url("~@/assets/icon/icon-select1.png");
        }
      }
      img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
.pagination {
  margin-top: 90px;
  margin-bottom: 100px;
}
</style>
